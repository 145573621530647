var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar",class:{'navbar-color': !_vm.isClient, 'navbar-color-cli': _vm.isClient}},[_c('div',{staticClass:"d-flex flex-row align-center justify-between w-100"},[_c('v-spacer'),_c('div',{staticClass:"d-flex justify-center flex-grow-1"},[_c('div',{attrs:{"id":"toolbar"}},[_c('div',{staticStyle:{"position":"relative"}},[(_vm.highlightField)?[_c('span',{staticClass:"ping"}),_c('span',{staticClass:"ping"}),_c('span',{staticClass:"ping"})]:_vm._e(),_c('v-autocomplete',{attrs:{"dense":"","outlined":"","clearable":!_vm.readonly,"return-object":"","hide-details":"","item-value":"id","item-text":"fantasia","placeholder":"Selecione uma empresa","items":_vm.clients,"value":_vm.clientId,"readonly":_vm.readonly},on:{"change":function($event){return _vm.doSelect($event)}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticClass:"autocomplete-item"},'v-list-item',attrs,false),on),[_vm._v(" "+_vm._s(item.fantasia)+" ")])]}}])})],2)])]),_c('div',{staticStyle:{"position":"absolute","bottom":"0px"}},[_vm._t("default")],2),_c('div',{staticClass:"d-flex flex-row justify-end align-center"},[_c('v-list',{staticStyle:{"z-index":"2","background-color":"transparent"},attrs:{"dense":"","dark":""}},[_c('v-list-item',[_c('v-list-item-avatar',[_c('img',{attrs:{"src":_vm.foto}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.nome))])],1)],1)],1),_c('v-menu',{attrs:{"offset-y":"","dense":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"z-index":"2"},attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',[_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'meu-perfil' })}}},[_c('v-list-item-title',{staticClass:"v-label-input"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-circle-outline")]),_vm._v("Meu Perfil ")],1)],1),(_vm.showAlocarHoras)?_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({ name: 'alocar-horas-galapos' })}}},[_c('v-list-item-title',{staticClass:"v-label-input"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-table-clock")]),_vm._v("Alocar Horas ")],1)],1):_vm._e(),_c('v-list-item',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.doLogout()}}},[_c('v-list-item-title',{staticClass:"v-label-input"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-power-standby")]),_vm._v("Logout ")],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }